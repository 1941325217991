import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Alert } from '@twilio-paste/alert';
import { Anchor } from '@twilio-paste/anchor';
import { Box } from '@twilio-paste/box';
import { Breadcrumb, BreadcrumbItem } from '../../../components/breadcrumb';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { Card } from '@twilio-paste/card';
import { Disclosure, DisclosureHeading, DisclosureContent } from '@twilio-paste/disclosure';
import { Flex } from '@twilio-paste/flex';
import { FormInput, FormLabel, FormHelpText } from '@twilio-paste/form';
import { Grid, Column } from '@twilio-paste/grid';
import { Heading } from '@twilio-paste/heading';
import { Paragraph } from '@twilio-paste/paragraph';
import { Stack } from '@twilio-paste/stack';
import { Text } from '@twilio-paste/text';
export const pageQuery = graphql`
  {
    mdx(fields: {slug: {eq: "/patterns/object-details/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageAside = makeShortcode("PageAside");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <content>
      <Breadcrumb mdxType="Breadcrumb">
  <BreadcrumbItem to="/" mdxType="BreadcrumbItem">Home</BreadcrumbItem>
  <BreadcrumbItem to="/patterns" mdxType="BreadcrumbItem">Patterns</BreadcrumbItem>
      </Breadcrumb>
      <Heading as="h1" variant="heading10" mdxType="Heading">
  {props.pageContext.frontmatter.title}
      </Heading>
      <Paragraph mdxType="Paragraph">{props.pageContext.frontmatter.description}</Paragraph>
      <Box as="dl" display="flex" mdxType="Box">
  <Text as="dt" color="colorTextWeak" lineHeight="lineHeight30" mdxType="Text">
    <Box width="size10" mdxType="Box">Status</Box>
  </Text>
  <Text as="dd" mdxType="Text">alpha</Text>
      </Box>
      <Callout variant="warning" mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">This is an ALPHA pattern, and we need your feedback!</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    This pattern is still going through feedback cycles, so if you have feedback or ideas related to this pattern,
    please create a <Anchor href="https://github.com/twilio-labs/paste/discussions" mdxType="Anchor">GitHub Discussion</Anchor> and let
    us know.
  </CalloutText>
      </Callout>
    </content>
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Ingredients`}</h2>
        <Grid gutter="space30" marginBottom="space80" mdxType="Grid">
  <Column mdxType="Column">
    <Stack orientation="vertical" spacing="space50" mdxType="Stack">
      <Card mdxType="Card">
        <Heading as="h3" variant="heading40" marginBottom="space0" mdxType="Heading">
          <Anchor href="/components/heading" mdxType="Anchor">Heading</Anchor>
        </Heading>
      </Card>
      <Card mdxType="Card">
        <Heading as="h3" variant="heading40" marginBottom="space0" mdxType="Heading">
          <Anchor href="/components/tabs" mdxType="Anchor">Tabs</Anchor>
        </Heading>
      </Card>
      <Card mdxType="Card">
        <Heading as="h3" variant="heading40" marginBottom="space0" mdxType="Heading">
          <Anchor href="/primitives/text" mdxType="Anchor">Text</Anchor>
        </Heading>
      </Card>
    </Stack>
  </Column>
  <Column mdxType="Column">
    <Stack orientation="vertical" spacing="space50" mdxType="Stack">
      <Card mdxType="Card">
        <Heading as="h3" variant="heading40" marginBottom="space0" mdxType="Heading">
          <Anchor href="/primitives/box" mdxType="Anchor">Box</Anchor>
        </Heading>
      </Card>
      <Card mdxType="Card">
        <Heading as="h3" variant="heading40" marginBottom="space0" mdxType="Heading">
          <Anchor href="/layout/stack" mdxType="Anchor">Stack</Anchor>
        </Heading>
      </Card>
      <Card mdxType="Card">
        <Heading as="h3" variant="heading40" marginBottom="space0" mdxType="Heading">
          <Anchor href="/layout/grid" mdxType="Anchor">Grid</Anchor>
        </Heading>
      </Card>
    </Stack>
  </Column>
  <Column mdxType="Column">
    <Card mdxType="Card">
      <Heading as="h3" variant="heading40" mdxType="Heading">
        Description list
        <br />
        (coming soon)
      </Heading>
      <Paragraph marginBottom="space0" mdxType="Paragraph">
        Learn more <Anchor href="#standard-details" mdxType="Anchor">about description lists and how to compose them</Anchor> below.
      </Paragraph>
    </Card>
  </Column>
        </Grid>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`// import all components for Object details patterns

import {Heading, Tabs, TabList, Tab, TabPanels, TabPanel, Text, Box, Stack, Grid, Column} from '@twilio-paste/core';
`}</code></pre>
        <h2>{`Usage`}</h2>
        <h2>{`General usage`}</h2>
        <p>{`An object details page or panel gives a customer a read-only view of their information, such as a message log or sent email.`}</p>
        <p>{`Object details should:`}</p>
        <ul>
          <li parentName="ul">{`Be clearly separated into content sections with headings, and if necessary, tabs to allow a customer to easily scan for relevant information.`}</li>
          <li parentName="ul">{`Prioritize the order of content based on what customers find most important from customer research.`}</li>
        </ul>
        <h3>{`Accessibility`}</h3>
        <p>{`A well-structured `}<a parentName="p" {...{
            "href": "https://www.w3.org/WAI/tutorials/page-structure/"
          }}>{`document hierarchy`}</a>{` helps provide efficient in-page navigation for keyboard users, assistive technologies, and mobile web users.`}</p>
        <p>{`Proper hierarchy allows users to skip directly to content that is most relevant to them. This is especially important on an object details page where there may be many different types of information and customers are more likely to skim through.`}</p>
        <p>{`To structure a page:`}</p>
        <ul>
          <li parentName="ul">{`Use headings to organize the page.`}</li>
          <li parentName="ul">{`Use headings in sequential order. For example, don’t place an H4 directly after an H2.`}</li>
          <li parentName="ul">{`Use description lists to define relationships between a property and its label.`}</li>
          <li parentName="ul">{`Use tables to organize data that are meant to be compared.`}</li>
        </ul>
        <h2>{`Variations`}</h2>
        <h3>{`Standard details`}</h3>
        <p>{`The most common way to structure an object details page is to first show an overview of properties in a description list, followed by more detailed content sections. Properties should be left-aligned, and property labels and values should be vertically placed.`}</p>
        <Callout mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">Composing a Description List</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    <Paragraph mdxType="Paragraph">
      A list of properties should be structured into a description list using the{' '}
      <Anchor href="/primitives/box" mdxType="Anchor">Box</Anchor> and <Anchor href="/primitives/text" mdxType="Anchor">Text</Anchor> primitives (e.g.,{' '}
      <Text as="span" fontFamily="fontFamilyCode" mdxType="Text">
        Box as="dl"
      </Text>
      , <Text as="span" fontFamily="fontFamilyCode" mdxType="Text">
        Text as="dd"
      </Text>) to help define the relationship between a property and its label, and spaced with the{' '}
      <Anchor href="/layout/stack" mdxType="Anchor">Stack</Anchor> component.
    </Paragraph>
    <Paragraph mdxType="Paragraph">
      In the future, this composition will be replaced with a Description List component.{' '}
      <Anchor href="https://css-tricks.com/utilizing-the-underused-but-semantically-awesome-definition-list/" showExternal mdxType="Anchor">
        Read more about description lists
      </Anchor>
    </Paragraph>
  </CalloutText>
        </Callout>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1632px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "77.45098039215686%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsSAAALEgHS3X78AAABvklEQVQoz41SXU/UQBTtr1J5goVfgk8ur8ZEf4aJ/0QT1w2oidEgRH3QRUCopfs9bbedj05nOtOuZ2cNsGCJJyeT25s5957eud6zpy8e3NvearU3N9pbrZ3NDRDxTmu93Vp/9E/i8tr9h08eP/cOD3qvXn7Y7X7e2z3odD51u/vdN/ud1x/x+f7dlya+3Tv89vXYs9bOHaSU48mkPxxNCQnCPuMcybqu542oPSEkY6IodJ5LP+iHg9GloHYwxoL1LRhjPGg4z7UuEZz7F1MSC5EzxinlSKJEnMyyjC1qzVdcwPJfsVK6LMuMMsisg7G2qipcyqXMKL1tekWslIqiBKd1mkswLpCnjDeK4VDkxZjMmJAiz6/741ws7FT2rs5SG8Ks0DftpVSkXLv53WG7NJmsCnNTvPAiSzewZtsIRmOCIS+mm7HUcZbSLKNJMkOMPBgnKahUudIZ+uOTc/93iBPsHZ3+6J0e/fz1vXdydhYEF4MwHPp+GASDwXCC1cBbXImxCRgpxsMxukJLqdwT6KJQyz3B27lg+Yj19c6llMVoPI3iBFsx/w9ciVEe1fB7UxIREpMoxqotWzQBFv4AFagx2yxvWygAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Screen of message details and list of properties",
              "title": "Screen of message details and list of properties",
              "src": "/static/ff630b3e2d75cad726996f4bfe3526fe/1d499/object-details-standard%403x.png",
              "srcSet": ["/static/ff630b3e2d75cad726996f4bfe3526fe/e7c18/object-details-standard%403x.png 408w", "/static/ff630b3e2d75cad726996f4bfe3526fe/b4098/object-details-standard%403x.png 816w", "/static/ff630b3e2d75cad726996f4bfe3526fe/1d499/object-details-standard%403x.png 1632w", "/static/ff630b3e2d75cad726996f4bfe3526fe/2ef06/object-details-standard%403x.png 2448w", "/static/ff630b3e2d75cad726996f4bfe3526fe/b27b8/object-details-standard%403x.png 3168w"],
              "sizes": "(max-width: 1632px) 100vw, 1632px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
        <Box marginBottom="space80" mdxType="Box">
  <Disclosure variant="contained" mdxType="Disclosure">
    <DisclosureHeading as="h4" variant="heading50" mdxType="DisclosureHeading">
      Show live example
    </DisclosureHeading>
    <DisclosureContent mdxType="DisclosureContent">TBD</DisclosureContent>
  </Disclosure>
        </Box>
        <p>{`If there are more than 6 properties, divide the properties into 2 columns.`}</p>
        <p>{`While this might be the most common structure for object details, use customer research to inform how to order the content sections on a details page. Show customers the most relevant information first.`}</p>
        <p>{`For example, when viewing the details of a sent email campaign, a customer may want to quickly see how well their campaign has been performing over time. In this case, placing a group of stats cards and a chart first may be most appropriate.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1632px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "77.45098039215686%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsSAAALEgHS3X78AAABuElEQVQoz41SS2/TQBD2r6rooQoJFeJvIC4k1/ZAe+YPIPETOIDEAQpS80JBQmqbF5yQuIbEseNX1m+vd+3w7TpKIkdRGH0azczONzO7O8rVq7ePTp4/qdZrj4HGHurVyssCtYrIeVqrPztvnJ2+uLx4owwHv79++dFp9791B83mXQto3QtIu9N++N4bA73euNMdtbujD58H7z8NPt70f/38o3DOV1LiJFmYpqYvdMPQTUPVtLmuE9cVZ3me5auAr6wof30bX99E74YMYSUIIs8L4jgBpjNdnetzbZGmTFJy6CzbahGRQWjGmBKGke+HYCLiOGRhmBjBMKw0TX0/cD0PBNteZlnm+aIHpXRJXLgYeU2OIkEmxAUZJSd/Z1GcOM7SNG3kzVQNJSzLQTJSMR2XsulMkUQp+rEkoYWGC2zcJEklhEEp25IRYozj8rCPQr4R3SeHeL9dIOL55WAx6REyjgjxCPFh/BfZx9kO1tkllMiFI1LDuKi9vp7E/rXLZKwBlcI5Hj4/hGJzoHfHTsWGqfPJVMVXrw4LCqdM7F957EhsGrXsJZrzLN+AFZqLpkEQmpZdbNg/MqUuMmiqndoAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Screen of email campaign details showing a chart first",
              "title": "Screen of email campaign details showing a chart first",
              "src": "/static/56bebc25b2f561a9da7a2740edde3937/1d499/object-details-standard-reordered%403x.png",
              "srcSet": ["/static/56bebc25b2f561a9da7a2740edde3937/e7c18/object-details-standard-reordered%403x.png 408w", "/static/56bebc25b2f561a9da7a2740edde3937/b4098/object-details-standard-reordered%403x.png 816w", "/static/56bebc25b2f561a9da7a2740edde3937/1d499/object-details-standard-reordered%403x.png 1632w", "/static/56bebc25b2f561a9da7a2740edde3937/2ef06/object-details-standard-reordered%403x.png 2448w", "/static/56bebc25b2f561a9da7a2740edde3937/b27b8/object-details-standard-reordered%403x.png 3168w"],
              "sizes": "(max-width: 1632px) 100vw, 1632px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
        <Box marginBottom="space80" mdxType="Box">
  <Disclosure variant="contained" mdxType="Disclosure">
    <DisclosureHeading as="h4" variant="heading50" mdxType="DisclosureHeading">
      Show live example
    </DisclosureHeading>
    <DisclosureContent mdxType="DisclosureContent">TBD</DisclosureContent>
  </Disclosure>
        </Box>
        <h3>{`Tabbed details`}</h3>
        <p>{`When you find that customers want to see multiple, top-level content sections, separate them with `}<a parentName="p" {...{
            "href": "../components/tabs"
          }}>{`Tabs`}</a>{`.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1632px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "67.40196078431373%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsSAAALEgHS3X78AAABcUlEQVQoz31Su07DQBD0VyFEExL4EURD0kIBn4HEn0CRFw8JiQhCoEJCNBRAnJxjx76n7+EzaweIZSeMpliddmbWu3ZOjs82N/Z26q16rdnYbi1Yrx2sZAN6as3dRmtrc//o8NQZPrxcnN/2uvdXl8NOe9Bu33U6g5vr0Ur2+6Nu/zFjb/j89OoYY9IcUkmE0NfYnUyRtTatAJ5okkY6nas0TrIHh1KOMRVCxNoymRptCOUiVklif5n81ZlpToDW2mGME8KkVODw9j7++HSVNtCXOeedy2S7JABG/hHHsRJxPEUe49zk0JlFNhznQitl87qIpRiSKeNTb8aFwARAMaFKaWgiVAQRBf+1YkgGmTtBEFhq8kPmBoKr9B+xjKVCng9rKDVhjBlj1eWXkycTZCrJYYSrjuVvhgLGTgqLWWwVxkFoBifQiV2fzEWESTWBUOYHcz9iTNrFr7Ja7Hn+PIyCIIRVF09avLM2NmQJGIH4G68IxT6tUBK1AAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Screen of call details with tabs and list of properties",
              "title": "Screen of call details with tabs and list of properties",
              "src": "/static/1922298da406cda23aeda7a88731d739/1d499/object-details-tabbed%403x.png",
              "srcSet": ["/static/1922298da406cda23aeda7a88731d739/e7c18/object-details-tabbed%403x.png 408w", "/static/1922298da406cda23aeda7a88731d739/b4098/object-details-tabbed%403x.png 816w", "/static/1922298da406cda23aeda7a88731d739/1d499/object-details-tabbed%403x.png 1632w", "/static/1922298da406cda23aeda7a88731d739/2ef06/object-details-tabbed%403x.png 2448w", "/static/1922298da406cda23aeda7a88731d739/b27b8/object-details-tabbed%403x.png 3168w"],
              "sizes": "(max-width: 1632px) 100vw, 1632px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
        <Box marginBottom="space80" mdxType="Box">
  <Disclosure variant="contained" mdxType="Disclosure">
    <DisclosureHeading as="h4" variant="heading50" mdxType="DisclosureHeading">
      Show live example
    </DisclosureHeading>
    <DisclosureContent mdxType="DisclosureContent">TBD</DisclosureContent>
  </Disclosure>
        </Box>
        <h3>{`Placement of object actions`}</h3>
        <p>{`Many object detail pages also include a set of actions that can be performed on that object, such as editing or deleting.`}</p>
        <Callout variant="info" mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">This pattern is coming soon!</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    We've not yet patternized this part of Object Details. If you need this for your current or upcoming work, please
    consider partnering with us to <Anchor href="/patterns/contribute" mdxType="Anchor">contribute it</Anchor>.
  </CalloutText>
        </Callout>
        <h2>{`Starter kits`}</h2>
        <h3>{`CodeSandbox`}</h3>
        <p>{`Coming soon`}</p>
        <h3>{`Figma`}</h3>
        <p>{`Coming soon`}</p>
        <h2>{`Related discussions`}</h2>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/twilio-labs/paste/discussions/528"
            }}>{`Label Value styling`}</a></li>
        </ul>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      